@use "./variables";

@mixin transition($duration: 0.4s) {
    transition: all $duration ease;
}

@mixin flex($direction: row, $justify: center, $align: center) {
    display: flex;
    flex-direction: $direction;
    justify-content: $justify;
    align-items: $align;
}

@mixin resetLink($color: variables.$chocolate) {
    color: $color;
    text-decoration: none;
}

@mixin link($color: variables.$horizontalGradient) {
    @include flex(column, center, flex-start);
    color: variables.$chocolate;
    text-decoration: none;

    &::after {
        content: "";
        @include transition;
        width: 0%;
        height: 2px;
        margin-top: 2px;
        background: $color;
    }

    &:hover::after, &:focus::after {
        width: 100%;
    }
}

@mixin smallMobileAndUp {
    @media screen and (min-width: 370px){
        @content;
    }
}

@mixin mobileAndUp {
    @media screen and (min-width: 600px){
        @content;
    }
}

@mixin tabletAndUp {
    @media screen and (min-width: 800px){
        @content;
    }
}

@mixin laptopAndUp {
    @media screen and (min-width: 1100px){
        @content;
    }
}

@mixin monitorAndUp {
    @media screen and (min-width: 1400px){
        @content;
    }
}

@mixin XLMonitorAndUp {
    @media screen and (min-width: 1900px){
        @content;
    }
}