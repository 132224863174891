@use "../../../../utils/mixins";
@use "../../../../utils/variables";

.welcome {
    @include mixins.flex(column, flex-start, center);
    width: 100%;
    height: 100vh;
    background: variables.$verticalGradient;

    &_title {
        font-family: variables.$display;
        font-size: variables.$display-mobile-large;
        text-align: center;

        @include mixins.smallMobileAndUp {
            margin-top: 60px;
        }

        @include mixins.mobileAndUp {
            font-size: variables.$display-desktop-medium;
            margin-top: 0;
        }

        @include mixins.laptopAndUp {
            font-size: variables.$display-desktop-large;
        }
    }

    &_caption {
        font-family: variables.$copy;
        font-size: variables.$copy-mobile-large;
        text-align: center;
        margin: 8vh 0;
        width: 85%;

        @include mixins.mobileAndUp {
            font-size: variables.$copy-desktop-medium;
        }

        @include mixins.laptopAndUp {
            font-size: variables.$copy-desktop-large;
        }
    }

    &_button {
        @include mixins.transition;
        color: variables.$chocolate;
        font-family: variables.$display;
        font-size: variables.$display-mobile-small;
        background-color: variables.$coconut;
        border: variables.$border;
        padding: 30px 40px;
        margin: 0 30px;

        @include mixins.mobileAndUp {
            font-size: variables.$display-desktop-small;
            margin: 0;
        }

        @include mixins.laptopAndUp {
            font-size: variables.$display-desktop-medium;
        }

        &:hover, &:focus {
            cursor: pointer;
            box-shadow: 15px 15px 0 variables.$chocolate;
        }
    }
}