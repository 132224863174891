@use "../../utils/mixins";
@use "../../utils/variables";

.project {
    position: relative;

    @include mixins.laptopAndUp {
        @include mixins.flex(row-reverse, space-between, flex-start);
    }

    &_images {
        @include mixins.laptopAndUp {
            width: 50%;
        }

        &_thumbnail {
            width: 100%;

            &_photo {
                width: 100%;
                display: block;
            }
        }

        &_illustration {
            width: 100%;

            &_photo {
                width: 100%;
                display: block;
            }
        }
    }

    &_caption {
        padding: 30px;

        @include mixins.mobileAndUp {
            padding: 80px;
        }

        @include mixins.laptopAndUp {
            position: sticky;
            width: 50%;
            left: 50%;
            top: 170px;
        }
        
        & > *:not(:last-child) {
            margin-bottom: 40px;
        }

        &_title {
            font-family: variables.$display;
            font-size: variables.$display-mobile-medium;

            @include mixins.mobileAndUp {
                font-size: variables.$display-desktop-large;
            }
        }

        &_tags {
            @include mixins.flex(row, flex-start, center);
            flex-wrap: wrap;
            width: 100%;

            &_tag {
                min-width: 6rem;
                padding: 0.5rem 1rem 0.25rem;
                border: variables.$border;
                border-radius: 500px;
                font-family: variables.$copy;
                font-size: variables.$copy-mobile-medium;
                margin-bottom: 10px;
                margin-right: 20px;
                text-align: center;

                @include mixins.smallMobileAndUp {
                    min-width: 8rem;
                }

                @include mixins.mobileAndUp {
                    margin-right: 30px;
                }

                @include mixins.laptopAndUp {
                    font-size: variables.$copy-desktop-small;
                    min-width: 10rem;
                    padding: 1rem 1rem 0.75rem;
                }
            }
        }

        &_description {
            font-size: variables.$copy-desktop-small;

            &:first-of-type {
                font-style: italic;
            }
        }

        &_links {
            @include mixins.flex(row, flex-start, flex-start);
            flex-wrap: wrap;

            &_button {
                @include mixins.resetLink;
                @include mixins.transition;
                position: relative;
                border: variables.$border;
                background: variables.$coconut;
                padding: 1rem 1.5rem;
                font-family: variables.$display;
                font-size: variables.$copy-mobile-large;
                box-shadow: 15px 15px 0 rgba(79, 0, 11, 1);
                margin-bottom: 40px;
                margin-right: 15px;
                width: 100%;

                @include mixins.mobileAndUp {
                    width: fit-content;

                    &:not(:last-child) {
                        margin-right: 40px;
                    }

                    &:last-child {
                        margin-right: 0;
                    }
                }

                &::after {
                    content: "";
                    @include mixins.transition;
                    display: block;
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    background: variables.$verticalGradient;
                    top: 15px;
                    left: 15px;
                    z-index: -1;
                }

                &:hover, &:focus {
                    box-shadow: 15px 15px 0 rgba(79, 0, 11, 0);
                }
            }
        }
    }
}