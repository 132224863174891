@use "../../utils/mixins";
@use "../../utils/variables";
@use "sass:color";

.header {
    @include mixins.flex(row, space-between, center);
    width: 100%;
    padding: 30px;
    position: relative;
    z-index: 10;

    @include mixins.mobileAndUp {
        padding: 50px 80px;
    }

    &--light {
        position: sticky;
        top: 0;
        background-color: variables.$coconut;

        &::after {
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 2px;
            background: variables.$horizontalGradient;
        }
    }

    &_title {
        font-family: variables.$display;
        font-size: variables.$display-mobile-small;

        @include mixins.mobileAndUp {
            font-size: variables.$display-desktop-small;
        }

        &--light {
            @include mixins.link(variables.$horizontalGradient);
        }

        &--bright {
            @include mixins.link(variables.$chocolate);
        }
    }

    &_languageSwitch {
        @include mixins.transition;
        border: variables.$border;
        padding: calc(8rem / 16);
        border-radius: 500px;
        cursor: pointer;

        @include mixins.mobileAndUp {
            padding: calc(10rem / 16);
        }

        &--light {
            &--en {
                background-color: variables.$banana;
                box-shadow: -30px 0px 30px -5px variables.$strawberry inset;

                @include mixins.mobileAndUp {
                    box-shadow: -60px 0px 30px -5px variables.$strawberry inset;
                }
    
                &:hover {
                    background-color: color.scale(variables.$banana, $lightness: 20%);
                    box-shadow: -30px 0px 30px -5px color.scale(variables.$strawberry, $lightness: 20%) inset;

                    @include mixins.mobileAndUp {
                        box-shadow: -60px 0px 30px -5px color.scale(variables.$strawberry, $lightness: 20%) inset;
                    }
                }
            }

            &--fr {
                background-color: variables.$banana;
                box-shadow: 30px 0px 30px -5px variables.$strawberry inset;

                @include mixins.mobileAndUp {
                    box-shadow: 60px 0px 30px -5px variables.$strawberry inset;
                }
    
                &:hover {
                    background-color: color.scale(variables.$banana, $lightness: 20%);
                    box-shadow: 30px 0px 30px -5px color.scale(variables.$strawberry, $lightness: 20%) inset;

                    @include mixins.mobileAndUp {
                        box-shadow: 60px 0px 30px -5px color.scale(variables.$strawberry, $lightness: 20%) inset;
                    }
                }
            }
        }

        &--bright {
            &--en {
                background: none;
                box-shadow: 30px 0px 30px -5px variables.$pineapple inset;

                @include mixins.mobileAndUp {
                    box-shadow: 60px 0px 30px -5px variables.$pineapple inset;
                }
    
                &:hover {
                    box-shadow: 30px 0px 30px -5px color.scale(variables.$pineapple, $lightness: 20%) inset;

                    @include mixins.mobileAndUp {
                        box-shadow: 60px 0px 30px -5px color.scale(variables.$pineapple, $lightness: 20%) inset;
                    }
                }
            }

            &--fr {
                background: none;
                box-shadow: -30px 0px 30px -5px variables.$pineapple inset;

                @include mixins.mobileAndUp {
                    box-shadow: -60px 0px 30px -5px variables.$pineapple inset;
                }
    
                &:hover {
                    box-shadow: -30px 0px 30px -5px color.scale(variables.$pineapple, $lightness: 20%) inset;

                    @include mixins.mobileAndUp {
                        box-shadow: -60px 0px 30px -5px color.scale(variables.$pineapple, $lightness: 20%) inset;
                    }
                }
            }
        }

        &_language {
            @include mixins.transition;
            @include mixins.flex;
            height: 2rem;
            width: 2rem;
            border-radius: 50%;
            border: variables.$border;

            @include mixins.mobileAndUp {
                height: 3rem;
                width: 3rem;
            }
            
            &--light {
                background-color: variables.$coconut;
            }

            &--bright {
                background-color: variables.$pineapple;
            }
    
            &--en {
                margin-right: calc(32rem / 16);

                @include mixins.mobileAndUp {
                    margin-right: calc(70rem / 16);
                }
            }
    
            &--fr {
                margin-left: calc(32rem / 16);
                @include mixins.mobileAndUp {
                    margin-left: calc(70rem / 16);
                }
            }
    
            &_text {
                position: relative;
                top: 0.15rem;
                font-family: variables.$copy;
                font-size: variables.$copy-mobile-small;
                color: variables.$chocolate;

                @include mixins.mobileAndUp {
                    font-size: variables.$copy-desktop-small;
                }
            }
        }
    }
}