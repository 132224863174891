@use "../../../../../utils/mixins";
@use "../../../../../utils/variables";

.projectCard {
    @include mixins.resetLink;
    @include mixins.flex(column, flex-start, center);
    @include mixins.transition;
    position: relative;
    width: 90%;
    margin-bottom: 60px;
    border: variables.$border;
    box-shadow: 20px 20px 0 rgba(79, 0, 11, 0);
    cursor: pointer;

    @include mixins.tabletAndUp {
        width: 100%;
        margin-bottom: 0px;
        box-shadow: none;
    }

    &::after {
        content: "";
        @include mixins.transition;
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        background: variables.$verticalGradient;
        top: 20px;
        left: 20px;
        z-index: -1;
    }

    &:hover, &:focus {
        box-shadow: 20px 20px 0 rgba(79, 0, 11, 1);

        @include mixins.tabletAndUp {
            box-shadow: none;
        }
    }

    &:hover &_caption, &:focus &_caption {
        opacity: 1;
    }

    &_thumbnail {
        @include mixins.flex;
        width: 100%;

        @include mixins.tabletAndUp {
            height: 100%;
        }

        &_photo {
            width: 100%;
            object-fit: cover;
            aspect-ratio: 1;
        }
    }

    &_caption {
        @include mixins.transition;
        @include mixins.flex(column, center, flex-start);
        width: 100%;
        padding: 20px;
        border-top: variables.$border;
        background: variables.$coconut;

        @include mixins.tabletAndUp {
            align-items: center;
            opacity: 0;
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            background: variables.$verticalGradient;
            box-shadow: 20px 20px 0 variables.$chocolate;
            padding: 40px;
            border-top: none;
        }

        &_name {
            font-family: variables.$display;
            font-size: variables.$display-mobile-small;
            margin-bottom: 1rem;

            @include mixins.smallMobileAndUp {
                font-size: variables.$display-mobile-medium;
            }

            @include mixins.tabletAndUp {
                text-align: center;
            }

            @include mixins.monitorAndUp {
                font-size: variables.$display-desktop-medium;
            }
        }

        &_type {
            font-family: variables.$copy;
            font-size: variables.$copy-mobile-small;

            @include mixins.smallMobileAndUp {
                font-size: variables.$copy-mobile-medium;
            }

            @include mixins.tabletAndUp {
                text-align: center;
            }

            @include mixins.monitorAndUp {
                font-size: variables.$copy-desktop-medium;
            }
        }
    }
}