$coconut: #FFF9F5;
$pineapple: #FEE485;
$banana: #FEC777;
$mango: #FFB381;
$strawberry: #FFA08C;
$chocolate: #4F000B;

$verticalGradient: linear-gradient(to bottom, $banana, $strawberry);
$horizontalGradient: linear-gradient(to right, $banana, $strawberry);

$border: 1px solid $chocolate;

$display: 'Victor Mono', monospace;
$copy: "CooperHewitt", sans-serif;

$display-desktop-large: 4.5rem;
$display-desktop-medium: 3.75rem;
$display-desktop-small: 2.25rem;

$display-mobile-large: 3rem;
$display-mobile-medium: 2.25rem;
$display-mobile-small: 1.5rem;

$copy-desktop-large: 2.5rem;
$copy-desktop-medium: 2rem;
$copy-desktop-small: 1.5rem;

$copy-mobile-large: 1.5rem;
$copy-mobile-medium: 1.25rem;
$copy-mobile-small: 1rem;