@use "../../../../utils/mixins";
@use "../../../../utils/variables";

.projects {
    margin: 50px 30px;

    @include mixins.mobileAndUp {
        margin: 110px 80px;
    }

    &_title {
        font-family: variables.$display;
        font-size: variables.$display-mobile-medium;
        text-align: center;

        @include mixins.mobileAndUp {
            font-size: variables.$display-desktop-large;
        }
    }

    &_filter {
        @include mixins.flex(column, center, center);
        margin: 60px 0px;

        &_title {
            font-family: variables.$copy;
            font-size: variables.$copy-mobile-medium;
            margin-bottom: 20px;

            @include mixins.mobileAndUp {
                font-size: variables.$copy-desktop-small;
            }
        }

        &_taglist {
            @include mixins.flex(row, center, center);
            flex-wrap: wrap;
            width: 100%;
            margin-left: 20px;
            
            @include mixins.mobileAndUp {
                margin-left: 40px;
            }

            &_tag {
                min-width: 6rem;
                padding: 0.5rem 1rem 0.25rem;
                border: variables.$border;
                border-radius: 500px;
                font-family: variables.$copy;
                font-size: variables.$copy-mobile-medium;
                margin-bottom: 10px;
                margin-right: 20px;

                @include mixins.smallMobileAndUp {
                    min-width: 8rem;
                }

                @include mixins.mobileAndUp {
                    margin-right: 40px;
                }

                @include mixins.laptopAndUp {
                    font-size: variables.$copy-desktop-small;
                    min-width: 10rem;
                    padding: 1rem 1.5rem 0.75rem;
                }

                &--notSelected {
                    color: variables.$chocolate;
                    background: none;
                }

                &--selected {
                    color: variables.$coconut;
                    background: variables.$chocolate;
                }

                &:hover, &:focus {
                    cursor: pointer;
                    color: variables.$chocolate;
                    background-color: variables.$banana;
                    box-shadow: -50px 0px 30px -5px variables.$strawberry inset;

                    @include mixins.smallMobileAndUp {
                        box-shadow: -70px 0px 30px -5px variables.$strawberry inset;
                    }
                }
            }
        }
    }

    &_list {
        @include mixins.flex(column, flex-start, center);

        @include mixins.tabletAndUp {
            display: grid;
            grid-gap: 60px;
            grid-template-columns: repeat(2, 1fr);
        }

        @include mixins.laptopAndUp {
            grid-template-columns: repeat(3, 1fr);
        }

        &_error {
            font-family: variables.$copy;
            font-size: variables.$copy-desktop-small;
            text-align: center;
        }
    }
}